<template>
  <table cellspacing="0" cellpadding="0">
    <slot/>
  </table>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1.5rem;
}
td {
  padding: 0.5rem;
  vertical-align: middle;
  border: 1px solid #605c5c;
}
</style>